import { createStyles } from '@material-ui/core';

const styles = () =>
    createStyles({
        subtitle: {
            marginTop: '18px'
        },
        loading_box: {
            margin: '2em',
            display: 'flex',
            'justify-content': 'space-evenly'
        }
    });

export default styles;
