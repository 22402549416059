import { createStyles } from '@material-ui/core';

const styles = () =>
    createStyles({
        background: {
            width: '100%',
            height: '100%',
            backgroundColor: '#f1f1f3'
        }
    });

export default styles;
