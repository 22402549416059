import { StreamChat } from 'stream-chat';
import axios, { AxiosRequestConfig } from "axios";
import { User } from '../types/User';
import { CHAT_SERVICE_KEY, CHAT_FNS } from '../config';

export type GroupChatConfig = {
    groupName: string;
    usersSelected: string[];
}

export const createChannel = async (chatClient:StreamChat, groupConfig:GroupChatConfig): Promise<void> => {
    try {
        const { user } = chatClient;
        const channel = await chatClient.channel('messaging', '', {
            name: groupConfig.groupName,
            members: [ user.id , ...groupConfig.usersSelected ],
        });
        await channel.watch({});
    }
    catch (err) {
        throw err;
    }
};

export const getChatClient = async (user: User, apiToken: string) => {
    try {

        const data = {"id": `${user.id}` };
        const options: AxiosRequestConfig = {
            responseType: 'json',
            headers: {
              'Content-Type': 'application/json',
              'authorization': `Token ${apiToken}`,
            }
        };

        const { data: { token } } = await axios.post(`${CHAT_FNS}/auth-chat`, data, options);

        const chatClient = new StreamChat(CHAT_SERVICE_KEY);

        await chatClient.setUser({
            ...user,
            apiToken: apiToken,
        }, token);

        return chatClient;
    }
    catch (err) {
        throw err;
    }
};
