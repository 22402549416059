import React, { PureComponent } from 'react';
import { Avatar, withChatContext } from 'stream-chat-react';
import { StreamChat } from 'stream-chat'
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import AddGroupModal from '../AddGroupModal';

import { createChannel, GroupChatConfig } from '../../services/chat';
import Props from './Props';
import State from './State';
import { getAccountUsers } from "../../services/user";
import LoadingChannels from "../LoadingChannels";

export class ChannelListTeamCustom extends PureComponent<Props, State> {

  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      openModal: false,
      usersList: [],
    };

    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleModalClose = (groupConfig?:GroupChatConfig) => {
    this.setState({
      ...this.state,
      openModal: false
    });
    if (groupConfig != null) {
      const { client } = this.props;
      createChannel(client as StreamChat, groupConfig);
    }
  };

  createNewChannelHandler = async () => {
    this.setState({
      ...this.state,
      openModal: true,
    });
    const { client } = this.props;
    const { user } = client as StreamChat;
    const usersList = await getAccountUsers(user.apiToken);
    this.setState({
      ...this.state,
      usersList,
    });
  };

  render() {
    const { showSidebar, client, error, loading, children } = this.props;
    const { openModal, usersList } = this.state;
    const { user } = client as StreamChat;
    if (error) {
      return <p>Connection Error</p>;
    } else if (loading) {
      return <LoadingChannels />;
    } else {
      return (
        <div className="str-chat__channel-list-team">

          <AddGroupModal
            open={openModal}
            onClose={this.handleModalClose}
            usersList={usersList}
          />

          {showSidebar && (
            <div className="str-chat__channel-list-team__sidebar">
              <div className="str-chat__channel-list-team__sidebar--top">
                <Avatar
                  name={user.name || user.id}
                  size={50}
                />
              </div>
            </div>
          )}
          <div className="str-chat__channel-list-team__main">
            <div className="str-chat__channel-list-team__header">
              <div className="str-chat__channel-list-team__header--left">
                <Avatar
                  name={user.name || user.id}
                  size={40}
                />
              </div>
              <div className="str-chat__channel-list-team__header--middle">
                <div className="str-chat__channel-list-team__header--title">
                  {user.name || user.id}
                </div>
                <div
                  className={`str-chat__channel-list-team__header--status ${user.status}`}
                >
                  {user.status}
                </div>
              </div>
              <div className="str-chat__channel-list-team__header--right">
                <button
                  className="str-chat__channel-list-team__header--button"
                  onClick={ this.createNewChannelHandler }
                >
                  <GroupAddIcon />
                </button>
              </div>
            </div>
            {children}
          </div>
        </div>
      );
    }
  }
}

const ChannelListTeam = withChatContext(ChannelListTeamCustom);
export default ChannelListTeam;
