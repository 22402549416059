import React from "react";

const LoadingChannels = () => {
    return (
        <div className="str-chat__loading-channels">
            <div className="str-chat__loading-channels-item">
                <div className="str-chat__loading-channels-avatar" />
                <div className="str-chat__loading-channels-meta">
                    <div className="str-chat__loading-channels-username" />
                    <div className="str-chat__loading-channels-status" />
                </div>
            </div>
            <div className="str-chat__loading-channels-item">
                <div className="str-chat__loading-channels-avatar" />
                <div className="str-chat__loading-channels-meta">
                    <div className="str-chat__loading-channels-username" />
                    <div className="str-chat__loading-channels-status" />
                </div>
            </div>
            <div className="str-chat__loading-channels-item">
                <div className="str-chat__loading-channels-avatar" />
                <div className="str-chat__loading-channels-meta">
                    <div className="str-chat__loading-channels-username" />
                    <div className="str-chat__loading-channels-status" />
                </div>
            </div>
        </div>
    );
};

export default LoadingChannels;
