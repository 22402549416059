export const text = {
    es: {
      translation: {
        NEW_GROUP: 'Nuevo Grupo',
        PARTICIPANTS: 'Participantes',
        CANCEL: 'Cancelar',
        ACCEPT: 'Aceptar'
      }
    },
    en: {
      translation: {
        NEW_GROUP: 'New Group',
        PARTICIPANTS: 'Participants',
        CANCEL: 'Cancel',
        ACCEPT: 'Ok'
      }
    },
    pt: {
      translation: {
        NEW_GROUP: 'Novo grupo',
        PARTICIPANTS: 'Participantes',
        CANCEL: 'Cancelar',
        ACCEPT: 'Aceitar'
      }
    }
  };
