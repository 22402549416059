import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { CHAT_FNS } from '../config';
import { User } from '../types/User';

export const getUser = async (token: string) => {
    try {
        const options: AxiosRequestConfig = {
            responseType: 'json',
            headers: {
                'authorization': `Token ${token}`,
            }
        };

        const { data } = await axios.get(`${CHAT_FNS}/get_user`, options);
        return data as User;
    }
    catch (err) {
        const { response } = err as AxiosError<AxiosResponse>;
        throw response;
    }
};

export const getAccountUsers = async (apiToken: string): Promise<User[]> => {
    try {
        const options: AxiosRequestConfig = {
            responseType: 'json',
            headers: {
                'authorization': `Token ${apiToken}`,
            }
        };

        const { data } = await axios.get(`${CHAT_FNS}/query_users`, options);
        return data as User[];
    }
    catch (err) {
        const { response } = err as AxiosError<AxiosResponse>;
        throw response;
    }
};
