import React from 'react';
import {
    MessageList,
    MessageInput,
    Chat,
    Channel,
    ChannelHeader,
    Thread,
    Window,
    ChannelList,
} from 'stream-chat-react';

import 'stream-chat-react/dist/css/index.css';
import Props from './Props';

import ChannelListTeam from '../ChannelListTeam';
import { ChannelPreviewLastMessage } from "../ChannelPreviewLastMessage/ChannelPreviewLastMessage";

const SimpliChat = (props: Props) => {
    const { chatClient } = props;

    const filters = {
        type: 'messaging',
        members: {
            $in: [chatClient.user.id]
        }
    };

    const sort = { last_message_at: -1 };

    chatClient.on('message.new', (e: any) => {
        if (e.user.id !== chatClient.user.id) {
            const audio = new Audio('notification.mp3');
            audio.play();
        }
    });

    return (
        <Chat client={chatClient} theme={'messaging light'}>
            <ChannelList
                filters={filters}
                sort={sort}
                List={ChannelListTeam}
                Preview={ChannelPreviewLastMessage}
            />
            <Channel>
                <Window>
                    <ChannelHeader />
                    <MessageList />
                    <MessageInput />
                </Window>
                <Thread />
            </Channel>
        </Chat>
    );
};

export default SimpliChat;
