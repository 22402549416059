import React, { PureComponent } from 'react';
import { Avatar } from 'stream-chat-react';
import { Channel } from 'stream-chat';
import Props from "./Props";
import State from './State';

/**
 * Used as preview component for channel item in [ChannelList](#channellist) component.
 *
 * @example ./docs/ChannelPreviewLastMessage.md
 * @extends PureComponent
 */

export class ChannelPreviewLastMessage extends PureComponent<Props, State> {
    channelPreviewButton = React.createRef<HTMLButtonElement>();

    onSelectChannel = () => {
        this.props.setActiveChannel(this.props.channel);
        const current = this.channelPreviewButton.current;
        if (current) {
            current.blur();
        }
    };

    render() {
        const channel = this.props.channel as unknown as Channel;
        const unreadClass =
            this.props.unread >= 1 ? 'str-chat__channel-preview--unread' : '';
        const activeClass = this.props.active
            ? 'str-chat__channel-preview--active'
            : '';
        const name = channel.data.name || channel.cid;
        return (
            <div
                className={`str-chat__channel-preview ${unreadClass} ${activeClass}`}
            >
                <button onClick={this.onSelectChannel} ref={this.channelPreviewButton}>
                    {this.props.unread >= 1 && (
                        <div className="str-chat__channel-preview--dot" />
                    )}
                    <Avatar image={channel.data.image} />
                    <div className="str-chat__channel-preview-info">
                        <span className="str-chat__channel-preview-title">{name}</span>
                        <span className="str-chat__channel-preview-last-message">
              {!channel.state.messages[0]
                  ? 'Nothing yet...'
                  : this.props.latestMessage.substring(0, 20)
              }
            </span>
                        {this.props.unread >= 1 && (
                            <span className="str-chat__channel-preview-unread-count">
                {this.props.unread}
              </span>
                        )}
                    </div>
                </button>
            </div>
        );
    }
}
