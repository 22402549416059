import React from 'react';
import { CircularProgress } from "@material-ui/core";

const Loading = ({ active }: any) => {
    return active && <div>
        <CircularProgress />
    </div>
};

export default Loading;
