import { createStyles } from '@material-ui/core';

const styles = () =>
    createStyles({
        flex: {
            flex: 1,
        },
        menuButton: {
            marginLeft: -12,
            marginRight: 20,
        },
        root: {
            flexGrow: 1,
        },
    });

export default styles;
