import React, { Component } from 'react';
import { StreamChat } from 'stream-chat';

import Chat from '../components/Chat';
import DefaultPage from '../components/DefaultPage';

import withRoot from '../withRoot';
import styles from './styles';

import Loading from '../components/Loading';

import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";

import { getUrlParamsFromLocation } from '../utils/router.utils';
import { getUser } from '../services/user';
import { User } from '../types/User';
import { getChatClient } from '../services/chat';

import State from './State';
import Props from './Props';

export class App extends Component<Props, State> {

    state: State;

    constructor(props: Props) {
        super(props);

        this.state= {
            loading: true,
            isAuthed: false,
            chatClient: null,
        };
    }

    componentDidMount() {
        const { location } = this.props;
        const params: any = getUrlParamsFromLocation(location!, "/:token");
        if (params.token) {
            this.authHandler(params.token);
        }
        else {
            this.loadingHandler();
        }
    }

    authHandler = async (token: string) => {
        try {
            const user:User = await getUser(token);
            const chatClient = await getChatClient(user, token);
            if (chatClient) {
                this.chatInitHandler(chatClient);
            }
            else {
                this.loadingHandler();
            }
        }
        catch(err) {
            this.errorHandler(err);
            this.loadingHandler();
        }
    };

    chatInitHandler(chatClient: StreamChat) {
        this.setState({
            ...this.state,
            loading: false,
            isAuthed: true,
            chatClient
        });
    }

    errorHandler(error: any) {
        console.log(error);
    }

    loadingHandler() {
        const { loading } = this.state;
        this.setState({
            ...this.state,
            loading: !loading
        });
    }

    render() {
        const { classes } = this.props;
        const { chatClient, isAuthed, loading } = this.state;
        return (
            <div className={classes!.root}>
                { isAuthed
                    ? <Chat chatClient={ chatClient as StreamChat } />
                    : <DefaultPage />
                }
                <Loading active={loading} />
            </div>
        );
    }
}

export default withRoot(withStyles(styles)(withRouter(App as any)));
