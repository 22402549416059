import { RouteComponentProps, matchPath } from "react-router-dom";

interface Props extends RouteComponentProps<any> {}

export interface Location<Props> {
    pathname: string;
}

/**
 * Method to extract params from a location given a pathname.
 * @param {Location} location - location data with pathname to
 * extract params.
 * @param {string} path - expression to match location pathname.
 * @returns {object} object with parameters extracted from location.
 */
export const getUrlParamsFromLocation = (
    location: Location<Props>,
    path: string
): object => {

    const { pathname } = location;

    if (!pathname) {
        return {};
    }

    const match = matchPath(
        pathname, {
            path: path,
            exact: true,
            strict: false
        },
    );

    return match ? { ...match.params } : {};
};
