import React, { PureComponent } from 'react';

import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { CircularProgress } from "@material-ui/core";

import { withStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';

import { Avatar } from 'stream-chat-react';
import { User } from '../../types/User';
import Props from './Props';
import State from './State';
import styles from './styles';

export class AddGroupModal extends PureComponent<Props, State> {
  state: State;

  constructor(props: Props) {
    super(props);
    this.state = {
      usersSelected: [],
      groupName: '',
    }
  }

  private isUserSelected(userId: string): boolean {
    const { usersSelected } = this.state;
    return usersSelected.includes(userId);
  }

  private updateGroupName(value: string): void {
    this.setState({
      ...this.state,
      groupName: value
    })
  }

  private resetState(): void {
    this.setState({
      usersSelected: [],
      groupName: ''
    });
  }

  handleClose = ():void => {
    const { onClose } = this.props;
    onClose();
    this.resetState();
  };

  handleListItemClick = (userId: string):void => {
    let { usersSelected } = this.state;
    if (this.isUserSelected(userId)) {
      usersSelected = usersSelected.filter((userSelected) => userSelected !== userId);
    } else {
      usersSelected = usersSelected.concat([userId]);
    }
    this.setState({
        ...this.state,
      usersSelected
    })
  };

  confirmCreateGroup = ():void => {
    const { onClose } = this.props;
    onClose(this.state);
    this.resetState();
  };

  cancelCreateGroup = ():void => {
    const { onClose } = this.props;
    onClose();
    this.resetState();
  };

  groupNameLabel = () => (<Trans>NEW_GROUP</Trans>);

  render() {
    const { open, classes, usersList } = this.props;
    const { groupName, usersSelected } = this.state;
    return (
        <Dialog
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
            open={open}
        >
          <DialogContent>
            <TextField
                id="standard-basic"
                label={ this.groupNameLabel() }
                value={groupName}
                onChange={(event) => this.updateGroupName(event.target.value)}
            />
            <Typography variant="subtitle2" className={classes.subtitle}>
              <Trans>PARTICIPANTS</Trans>
            </Typography>
            {
              usersList.length === 0
              ? (
                  <div className={classes.loading_box}>
                    <CircularProgress />
                  </div>
              ) : (
                  <List>
                    { usersList.map((user:User) => (
                        <ListItem
                            button onClick={() => this.handleListItemClick(user.id)}
                            key={user.id}
                            selected={this.isUserSelected(user.id)}
                        >
                          <ListItemAvatar>
                            <Avatar
                                name={user.name}
                                size={35}
                            />
                          </ListItemAvatar>
                          <ListItemText primary={user.name} />
                        </ListItem>
                    ))}
                  </List>
              )
            }
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={this.cancelCreateGroup}
            >
              <Trans>CANCEL</Trans>
            </Button>
            <Button
              color="primary"
              onClick={this.confirmCreateGroup}
              disabled={ !groupName || !usersSelected.length }
            >
              <Trans>ACCEPT</Trans>
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

export default withStyles(styles)(AddGroupModal);
