import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import styles from './styles';
import Props from './Props';


export const DefaultPage = (props: Props)  => {
    const { classes } = props;
    return <div className={classes.background}></div>;
};

export default withStyles(styles)(DefaultPage);
